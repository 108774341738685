.wrapper :global(.MuiDialog-paper) {
	padding: 22px;
	border-radius: 16px;
}

.title {
	margin: 24px;
}

.title h2 {
	font-size: 34px;
	padding-top: 24px;
	margin: 0;
	font-family: Inter;
	font-weight: 700;
	line-height: 42px;
}

.title h2.primary {
	color: var(--oc-palette-primary-600);
}
.title h2.error {
	color: var(--oc-palette-error-main);
}
.title h2.info {
	color: var(--oc-palette-info-main);
}

.content {
	margin: 24px;
}

.actions {
	margin: 24px;
	bottom: 0;
}

.closeIcon {
	position: absolute;
	right: 16px;
	top: 16px;
	color: var(-oc-palette-secondary-800);
}
